.border{
  width: 100%;
  height: 100%;
  border: var(--group-border);
  background-color: var(--group-bg);
  box-sizing: border-box;

  &.square{
    border-radius: 60px;
  }

  &.round {
    border-radius: 90px;
  }

  &.circle {
    border-radius: 100%;
  }
}


