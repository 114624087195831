.form {
    display: grid;
    gap: 12px;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    padding-bottom: 90px;

    .content {
        padding: 16px 16px 0px 16px;
        position: relative;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto;
        gap: 24px;
        overflow: auto;
    }
}

.setting-step {
    display: grid;
    padding-right: 12px;
    grid-template-columns: auto;
    grid-auto-rows: max-content;
    gap: 24px;
    overflow: auto;


    .title {
        font: var(--font-13-r);
        color: var(--gl_text_secondary_2);
        font-weight: normal;
    }

    .bookingLabel {
        span {
            font: var(--font-12-r);
            font-weight: 400;
        }
    }

    .eachGroup {
        display: grid;
        grid-template-columns: auto;
        gap: 24px;

        .collapse {
            background-color: transparent;
        }

        .eachRule {
            display: grid;
            grid-template-columns: auto;
            align-items: center;
            padding: 12px;
            border: 1px solid var(--gl_dividerBorder_primary);
            gap: 24px;

            .dateinfo,
            .timeInfo,
            .delete,
            .schemaInfo {
                margin-top: 24px;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 8px;
            }

            .checkbox {
                :nth-child(2) {
                    border: 1px solid var(--gl_dividerBorder_primary);
                }
            }

            .delete {
                display: flex;
                justify-content: flex-end;

                p {
                    cursor: pointer;
                    font: var(--font-14-b);
                    color: var(--gl_text_constant_secondary_disabled2);
                    border-bottom: 1px dashed var(--gl_text_constant_secondary_disabled2);
                }
            }

            .timeInfo {
                grid-template-columns: repeat(2, auto);
            }

            .schemaInfo {
                grid-template-columns: auto;

                :first-child {
                    &> :not(:first-child) {
                        margin-top: 8px;
                        width: auto;
                    }
                }
            }
        }
    }

    .createNew {
        width: 100%;
        border: 1px dashed var(--gl_dividerBorder_accent);
        padding: 12px 0px;
        font: var(--font-14-b);
        color: var(--gl_dividerBorder_accent);
    }
}

.controls {
    width: -webkit-fill-available;
    height: 80px !important;
    padding: 10px;
    position: absolute;
    right: 0;
    bottom: 0px;
    box-sizing: border-box;

    & :first-child,
    :last-child {
        font: var(--font-14-b);
    }
}

.collapse {
    display: grid;
    grid-template-columns: 9fr 1fr;
    gap: 12px;
    padding: 0 !important;
    border: none !important;
    align-items: end;
    background-color: transparent !important;

    button {
        background-color: transparent !important;
        cursor: pointer;
    }
}

.currentRuleForm {
    display: grid;
    gap: 12px;
    box-sizing: border-box;
    grid-auto-rows: min-content;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    padding-bottom: 80px;

    .dateInfo {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 8px;
        align-items: flex-end;
    }

    .hours {
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
    }

    .schemaInfo {
        grid-template-columns: auto;

        :first-child {
            &> :not(:first-child) {
                margin-top: 8px;
            }
        }
    }
}