.modalPortal {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    &:empty {
        display: none;
    }
}

.modalOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.textContet {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    gap: 4px;
}

.title {
    max-width: 307px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: var(--gl_text_inverse);
}

.text {
    max-width: 308px;
    font-size: 14px;
    line-height: 14px;
    color: var(--gl_text_inverse);
}


.modal,
.errorModal {
    display: flex;
  width: auto;
    background: var(--gl_background_3);
    border-radius: 3px;
    max-height: 90vh;
    overflow: hidden;
    padding: 16px;
    gap: 16px;
    box-sizing: border-box;
}

.errorModal {
  overflow: auto;
  width: auto;

  .buttonBox {
    padding-bottom: 12px;
  }
}

.modalContent {
    padding: 0 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    min-height: 0;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    overflow: auto;
}

.buttonBox {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
}
