.tag {
  height: 18px;
  padding: 0 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gl_text_content_primary);
  background-color: var(--tags_constant_background_5);
  font-weight: bold;
  font: var(--font-12-r);
  line-height: normal;
  font-style: normal;

  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 5px;
  }
}

.status {
  color: var(--gl_text_content_primary);
}
