.container {
  width: 216px;
}

.header {
  color: var(--gray);
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.cancel {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.hint {
  color: var(--gray);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: block;
}