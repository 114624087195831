.switch-toggle-container {
  position: relative;
  display: flex;
  align-items: center;
}

.switch-toggle-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  background: #ddd;
  border-radius: 50px;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.switch-toggle::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
  transition: left 0.2s ease-in-out, width 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.switch-toggle--labels::after {
  content: 'Off';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #444;
}

.switch-toggle-input:checked + .switch-toggle {
  background: #4ba837;
}

.switch-toggle-input:checked + .switch-toggle::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-toggle-input:checked + .switch-toggle--labels::after {
  content: 'On';
}

.switch-toggle-input:disabled {
  pointer-events: none;
}

.switch-toggle-input:disabled + .switch-toggle {
  cursor: not-allowed;
  background: #eaeaea;
}

.switch-toggle-input:disabled + .switch-toggle::after {
  background: #f8f8f8;
}

.switch-toggle-container:active
  .switch-toggle-input:not([disabled])
  + .switch-toggle::after {
  width: 34px;
}
