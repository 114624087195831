.menu {
  width: 216px;
}

.status {
  & svg {
    fill: var(--gl_icon_inverse);
  }
}

.cancel {
  color: var(--petite-orchid);
  & > button:disabled {
    border: none;
    background: none;
  }
}

.cancelContent {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}

.cancelContentM {
  button {
    span {
      color: var(--gl_text_constant_danger);
    }
  }
}
