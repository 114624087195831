.textarea{
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 3px;
  background: var(--select_default_background_1);
  color: var(--gl_text_inverse);
  box-sizing: border-box;

  width: 100%;

  font-family: inherit;
  font: var(--font-12-r);


  padding: 8px 9px;

  &:active, &:focus {
    border-color: var(--gl_dividerBorder_accent);
  }

  &:disabled{
    background: var(--select_disabled_background);
    border-color: var(--gl_dividerBorder_primary);
    color: var(--gl_text_secondary_disabled);
    cursor: not-allowed;
    -webkit-text-fill-color: var(--gl_text_secondary_disabled); /* Override iOS / Android font color change */
    -webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
  }

  &::placeholder{
    color: var(--gl_text_secondary_disabled);
  }
}