@import 'styles/mixins';

#auth {
  width: 100%;
  height: 100vh;
  background-color: var(--catskill-white);
  display: flex;
  align-items: center;
  justify-content: center;

  .group {
    height: 525px;
    width: calc(980px);
    background-color: white;
    display: flex;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 2px 3px rgba(180, 199, 236, 0.35);

    .img-group {
      width: 50%;
      height: 100%;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(0.38);
      }
      .logo-group {
        color: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 30px;
        }
        h1 {
          font: var(--font-18-r);
          margin-bottom: 0;
          color: white;
        }
      }
    }
    .form-group {
      --auth-pad: 0 50px;
      --auth-pad-horiz: 50px;
      width: 50%;

      display: grid;
      grid-template-rows: min-content auto;

      .form-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > .error {
          width: calc(100% - 2 * var(--auth-pad-horiz));
          font: var(--font-16-b);
          color: #e08d8d;
          height: 20px;
          margin: 20px 0px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .top-block-A {
        padding: var(--auth-pad);
      }

      form {
        width: calc(100% - 2 * var(--auth-pad-horiz));
        padding: var(--auth-pad);
        align-self: center;

        h3 {
          margin-bottom: 10px;
          font: var(--font-14-b);
          color: var(--emperor);
        }
        input {
          font: var(--font-16-r);
          color: var(--tundora);
          margin-bottom: 21px;
          height: 60px;
          border-width: 1.5px;

          &:focus {
            border-color: var(--dodger-blue);
          }

          &.invalid {
            border-color: #e08d8d;
          }
        }

        button {
          width: 100%;
          height: 67px;
        }

        > div {
          position: relative;
        }
      }

      .footer {
        background-color: var(--selago);
        padding: var(--auth-pad);
        padding: 12px 50px;
        height: 90px;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid: 'title title' 'mail phone';
        align-items: center;
        .title {
          grid-area: title;
          font: var(--font-14-b);
          color: var(--gray-chateau);
        }
        .mail {
          display: flex;
          align-items: center;
          grid-area: mail;
        }
        .phone {
          display: flex;
          align-items: center;
          grid-area: phone;
        }

        .mail,
        .phone {
          img {
            margin-right: 10px;
          }
          color: var(--tundora);
          font: var(--font-16-r);
        }
      }
    }
  }
}

@include forDarkTheme {
  #auth {
    --icon-color: #fff;
    .group {
      box-shadow: none;
      border: 1px solid var(--06dp);
      background: var(--01dp);
      color: #fff;
      --emperor: #fff;
    }

    input {
      color: #fff !important;
      color-scheme: dark;
    }
  }
}
