.filter{
  width: 200px;
}

.guest {
  &:hover{
    background: var(--gl_text_accent);
  }
}

.subHeader {
  display: flex;
  gap: 12px;
}

.tags {
  grid-row: 3;
  grid-column: span 3;
}

.wrapper{
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
}

.guestDetail{
  path{
    fill: var(--gl_text_inverse);
  }
}

