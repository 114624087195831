.tableRoot {
  fill: transparent;
  stroke: transparent;
}

.selectedWrapper {
  overflow: visible;
}

.tableGroup {
  display: flex;
  margin: 10px;
  place-content: center;
  --count-color: var(--floorplanNumber_default_text);
  --count-bg: var(--floorplanNumber_default_background);
  --tb-border: none;
  --group-outline: solid 2px;
  --group-bg: var(--scheme-bg);

  foreignObject {
    overflow: visible;
  }

  &.moveSource,
  &.selectedTable {
    --tb-border: 4px double var(--floorplanTable_text);
    --count-bg: var(--floorplanNumber_focus_background);
    --count-color: var(--floorplanNumber_focus_text);
    --group-outline: red solid 2px;
    --outline-offset: -3px;
    --group-bg: transparent;
  }

  &.moveTarget {
    --tb-border: 4px double var(--gl_dividerBorder_accent);
    --count-bg: var(--floorplanNumber_focus_background);
    --count-color: var(--floorplanNumber_focus_text);
    --group-outline: none;
    --group-bg: transparent;
  }
}

.tableWrapper {
  border: var(--tb-border);
  box-sizing: border-box;
  grid-area: 1 / 1;
  overflow: visible;
  margin-top: 10px;
  outline: var(--group-outline);
  outline-offset: var(--outline-offset);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.tableBody {
  width: 100%;
  height: 100%;
  overflow: unset;
  user-select: none;
}

.tableInfo {
  display: flex;
  align-items: center;

  gap: 3px;
  & > span {
    font: var(--font-16-b);
    color: var(--floorplanTable_text);
    line-height: 13px;
  }
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.bookingInfo {
  font: var(--font-20-b);
  color: var(--floorplanTable_text);
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fullName {
  margin-top: 2px;
  font: var(--font-12-r);
  color: var(--gl_text_inverse);
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.selectedFullName {
    display: flex;
    overflow: visible;
  }

  p {
    margin: 0;
  }
}

.square {
  border-radius: 15px;
}

.circle {
  border-radius: 100%;
}

.borderWrapper {
  display: grid;
  justify-items: center;
  align-content: baseline;
}

.border {
  --tb-border: 4px solid var(--scheme-bg);
  background-color: var(--group-bg);
  &.square {
    border-radius: 20px;
  }
}
