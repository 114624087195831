.tableGroup {
  --tb-background: var(--floorplanTable_empty_background);
  --count-border: var(--floorplanNumber_empty_stroke);
  --count-color: var(--floorplanNumber_default_text);
  --count-bg: var(--floorplanNumber_default_background);
  --tb-border: none;
  --group-border: 7px solid var(--tb-background);
  --group-shadow: 0 0 10px var(--tb-background);
  --group-bg: var(--scheme-bg);
  user-select: none;

  foreignObject {
    overflow: visible;
  }

  &.red {
    --tb-background: var(--floorplanTable_outstayed_background);
    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_outstayed_background);
  }

  &.yellow {
    --tb-background: var(--floorplanTable_late_background);
    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_late_background);
  }

  &.green {
    --tb-background: var(--floorplanTable_arrived_background);
    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_arrived_background);
  }

  &.light_grey {
    --tb-background: var(--floorplanTable_confirmed_background);
    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_confirmed_background);
  }

  &.purple {
    --tb-background: var(--floorplanTable_manager_background);
    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_manager_background);
  }

  &.moveSource,
  &.selectedTable {
    --tb-border: 7px solid var(--floorplanTable_focus_stroke);
    --count-bg: var(--floorplanNumber_focus_background);
    --count-color: var(--floorplanNumber_focus_text);
    --group-border: none;
    --group-shadow: none;
    --group-bg: transparent;
  }

  &.moveTarget {
    --tb-border: 7px solid var(--gl_dividerBorder_accent);
    --count-border: var(--gl_dividerBorder_accent);
    --group-border: none;
    --group-shadow: none;
    --group-bg: transparent;
  }
}

.round {
  border-radius: 75px;
}

.square {
  border-radius: 45px;
}

.circle {
  border-radius: 100%;
}

.borderWrapper {
  overflow: visible;
}

.tableWrapper {
  background-color: var(--tb-background);
  border: var(--tb-border);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  overflow: visible;
}

.tableBody {
  width: 100%;
  height: 100%;
  overflow: unset;

  &.isDragging {
    width: 80px;
    height: 80px;
  }
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.tableInfo {
  display: flex;
  align-items: center;

  & > span {
    font: var(--font-26-r);
    color: var(--floorplanTable_text);
    line-height: 13px;
    font-size: 60px;
  }
}

.bookingInfo {
  font: var(--font-10-r);
  color: var(--floorplanTable_text);
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 75px;
}

.fullName {
  font: var(--font-40-r);
  text-overflow: ellipsis;
  color: var(--gl_text_inverse);
  text-align: center;
}

.guestIcon {
  flex: none;
  width: 90px;
  height: 70px;
}
