.notifications{
  display: flex;
  justify-content: center;
  align-items: center;

  :not(:first-child){
    margin-left: -15px;
  }
}

.iconWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: var(--shadow-strong);
}

.overbooking{
  width: 50px;
  height: 50px;

  background-color: var(--floorplanLegend_overbooking_background);
  color: var(--floorplanLegend_overbooking_content);

  font-size: 40px;
}

.endingSoon{
  width: 60px;
  height: 60px;
}

.vip{
  width: 52px;
  height: 52px;
}

.lockWrapper{
  width: 55px;
  height: 55px;
  background-color: var(--floorplanLegend_lock_background);
}

.lock{
  width: 40px;
  height: 40px;

  color: var(--floorplanLegend_lock_content);
  &>svg{
    width: 40px;
    height: 40px;
  }
}

.deposit{
  width: 50px;
  height: 50px;
  background-color: var(--floorplanLegend_deposite_background);
  color: var(--floorplanLegend_deposite_content);
  font-size: 35px;
}
