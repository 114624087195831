@mixin colors() {
  --alto: #e0e0e0;
  --alto-2: #cfcfcf;
  --wrf-alto: #313131;
  --athens-gray: #e7eaef;
  --botticelli: #d2daea;
  --catskill-white: #f2f4f8;
  --disabled: #f5f5f5;
  --mercury: #e2e2e2;
  --ghost: #cad0db;
  --heather: #bec5d1;
  --selago: #f1f5fe;
  --periwinkle-gray: #d5dded;
  --tropical-blue: #d2dffa;
  --gray-chateau: #9ea3ae;
  --cadet-blue: #abb0be;
  --silver-chalice: #ababab;
  --dusty-gray: #979797;
  --gray: #888;
  --boulder: #797979;
  --dove-gray: #6c6c6c;
  --dove-gray-2: #676767;
  --tundora: #4b4b4b;
  --tundora-dark: #464646;
  --emperor: #525252;
  --scorpion: #575757;
  --abbey: #484f54;
  --outer-space: #2d3239;
  --mine-shaft: #343434;
  --wrf-white: #ffffff;
  --pastel-green: #44de41;
  --chateau-green: #429867;
  --amazon: #2f754d;
  --dodger-blue: #177ff4;
  --petite-orchid: #e08d8d;
  --orange: #f4a917;
  --hokey-pokey: #d8bc2b;
  --hacienda: #9a861d;
  --saffron: #f1bb30;
  --green: #8db972;
  --blue: #589eec;
  --violet: #b874ed;
  --chestnut-rose: #cc5962;
  --sapphire: #315498;
  --copper-rust: #9a4d4d;
  --gold-6: #faad14;

  --warning: #ff7a45;

  --00dp: #121111;
  --01dp: #292929;
  --02dp: #323232;
  --03dp: #363636;
  --04dp: #3A3A3A;
  --06dp: #434343;
  --08dp: #474747;
  --12dp: #505050;
  --16p: #545454;

  --count-bg: var(--floorplanNumber_default_background);

  //Global colors

  //*Background*
  --gl_overlay_constant: #0000004D;
  --gl_background_1: #FFFFFF;
  --gl_background_2: #FFFFFF;
  --gl_background_3: #FFFFFF;
  --gl_background_gray_1: #FFFFFF;
  --gl_background_floorplan: #F6EEE6;
  --gl_background_accent: #E7F3E4;
  --gl_background_attention: #FE8F35;

  //*Text*
  --gl_text_inverse: #313131;
  --gl_text_secondary_2: #888888;
  --gl_text_secondary_disabled: #888888;
  --gl_text_inverse2: #FFFFFF;
  --gl_text_accent: #4BA837;
  --gl_status_arrived_text: #72E6F7;
  --gl_status_overstayed_text: #F7896F;
  --gl_status_late_text: #F7D694;
  --gl_status_confirmed_text: #60C689;
  --gl_status_notconfirmed_text: #ABABAB;
  --gl_status_wait_list_text: #CB94FF;
  --gl_status_noshow_text: #F75959;
  --gl_status_cancelled_text: #94CBF7;
  --gl_status_completed_text: #6FE583;
  --gl_status_starters_text: #CB94FF;
  --gl_status_main_text: #FA97FC;
  --gl_status_dessert_text: #FFAE52;
  --gl_status_checkdrop_text: #9EE189;
  --gl_status_paid_text: #60C689;
  --gl_status_bus_text: #F8CCFF;

  //*Text Constant*
  --gl_text_content_primary: #313131;
  --gl_text_constant_primary2: #FFFFFF;
  --gl_text_constant_secondary_disabled2: #ABABAB;
  --gl_text_constant_danger: #CC5962;

  //*Icon*
  --gl_icon_primary_1: #ABABAB;
  --gl_icon_inverse: #313131;
  --gl_icon_accent: #4BA837;

  //*Icon Constant*
  --gl_icon_constant_danger: #CC5962;
  --gl_icon_constant_primary: #ABABAB;
  --gl_icon_constant_primary2: #FFFFFF;
  --gl_icon_constant_secondary: #9B9B9B;
  --gl_icon_constant_disabled: #9B9B9BB2;

  //*Border/Divider*
  --gl_dividerBorder_primary: #D9D9D9;
  --gl_dividerBorder_primary_2: #D9D9D9;
  --gl_dividerBorder_secondary: #434343;
  --gl_dividerBorder_accent: #4BA837;
  --gl_dividerBorder_hover: #BBD2BC;

  //Control colors

  //*Header Dashboard*
  --header_background: #313131;

  //*Sidebar*
  --sidebar_inactive_background: #313131;
  --sidebar_active_background: #4BA837;

  //*Header Buttons*
  --headerButton_primary_background: #4BA837;
  --headerButton_secondary_background: #FFFFFF;

  //*Selects*
  --select_default_background_1: #FFFFFF;
  --select_default_background_2: #FFFFFF;
  --select_disabled_background: #F5F5F5;

  //*Selects menu*
  --selectMenu_default_background_1: #FFFFFF;
  --selectMenu_default_background_2: #FFFFFF;
  --selectMenu_hover_background: #E7F3E4;
  --selectMenu_focus_background: #BBD2BC;

  //*Checkbox*
  --сheckbox_default_background: #FFFFFF;
  --сheckbox_active_background: #4BA837;
  --сheckbox_disabled_background: #F5F5F5;

  //*Checkbox Constant*
  --сheckbox_constant_active_content: #FFFFFF;

  //*Radio button*
  --radioButton_default_background_1: #FFFFFF;
  --radioButton_default_background_2: #FFFFFF;
  --radioButton_active_background: #4BA837;
  --radioButton_disabled_background: #F5F5F5;

  //*Pickers*
  --pickerArrow_default_background_1: #FFFFFF;
  --pickerArrow_default_background_2: #FFFFFF;

  //*Collapse Button*
  --collapseButton_active_background: #D5DDED;

  //*Legend*
  --legendButton_default_background: #FFFFFF;
  --legendButton_active_background: #4BA837;
  --legendModal_background: #FFFFFF;

  //*Form button*
  --formButton_default_background: #FFFFFF;
  --formButton_active_background: #4BA837;
  --formButton_disabled_background: #F5F5F5;

  //*Column Button*
  --columnButton_active_background: #4BA837;
  --columnButton_secondary_background: #979797;

  //*Column Button Constant*
  --columnButton_constant_danger_background: #CC5962;
  --columnButton_constant_status_background: #F4A917;

  //*Scheme Button*
  --shemeButton_default_background: #4BA837;

  //*Button small*
  --buttonSmall_default_background: #4BA837;
  --buttonSmall_disabled_background: #F5F5F5;

  //*Button small Constant*
  --buttonSmall_constant_danger_background: #CC5962;

  //*Text button*
  --textButton_default_text: #4BA837;

  //*Zoom button*
  --zoomButton_default_background: #FFFFFF;
  --zoomButton_default_content: #6C6C6C;

  //*Date Picker*
  --dtpkr_background_1: #FFFFFF;
  --dtpkr_background_2: #FFFFFF;
  --dtpkrList_background: #FFFFFF;
  --dtpkrListItem_default_background: #FFFFFF;
  --dtpkrListItem_hover_background: #E7F3E4;
  --dtpkrListItem_active_background: #BBD2BC;
  --dtpkr_slot_hover_background: #E7F3E4;
  --dtpkr_slot_active_background: #4BA837;

  //*Title*
  --title_background_1: #FFFFFF;
  --title_background_2: #FFFFFF;
  --title_background_3: transparent;

  //*Badge constant*
  --badge_constant_background: #E08D8D;
  --badge_constant_content: #FFFFFF;

  //*Tags Constant*
  --tags_constant_background_1: #F4A917;
  --tags_constant_background_2: #8DB972;
  --tags_constant_background_3: #429867;
  --tags_constant_background_4: #589EEC;
  --tags_constant_background_5: #ABABAB;
  --tags_constant_background_6: #888888;
  --tags_constant_background_7: #CC5962;

  //*Status Constant*
  --status_constant_arrived_background: #72E6F7;
  --status_constant_overstayed_background: #F7896F;
  --status_constant_late_background: #F7D694;
  --status_constant_confirmed_background: #60C689;
  --status_constant_notconfirmed_background: #ABABAB;
  --status_constant_wait_list_background: #CB94FF;
  --status_constant_noshow_background: #F75959;
  --status_constant_cancelled_background: #94CBF7;
  --status_constant_completed_background: #6FE583;
  --status_constant_starters_background: #CB94FF;
  --status_constant_main_background: #FA97FC;
  --status_constant_dessert_background: #FFAE52;
  --status_constant_checkdrop_background: #9EE189;
  --status_constant_paid_background: #60C689;
  --status_constant_bus_background: #F8CCFF;

  //*Guests item list*
  --guestsIitemlist_default_background: #FFFFFF;
  --guestsIitemlist_hoverActive_background: #E7F3E4;

  //*Collapse*
  --collapse_waiting_background: #E7F3E4;
  --collapse_administrations_default_background: #E7F3E4;
  --collapse_administrations_active_background: #E7F3E4;

  //*Item row Administrations*
  --itemrow_administrations_background: #FFFFFF;

  //*Bottom bar*
  --bottomBar_background_1: #E7F3E4;
  --bottomBar_background_2: #E7F3E4;
  --bottomBar_background_3: #E7F3E4;

  //*Time Slider*
  --timeSliderPrts_text: #31313180;
  --timeSliderPrts_slider_active_background: #4BA837;
  --timeSliderPrts_slider_inactive_background: #C3CCC0;
  --timeSliderPrts_timeLablel_background: #4BA837;

  //*Time Slider Constant*
  --timeSliderPrts_constant_knob_background: #FFFFFF;

  //*Dropdown Button*
  --dropdownButton_acccent_background: #4BA837;
  --dropdownButton_secondary_background: #979797;

  //*Tooltip*
  --tooltip_background: #3A3A3A;

  //*Table*
  --table_default_background: transparent;
  --table_gray_background: #FBFBFB;
  --table_amaunt_background: #E7F3E4;

  //*New card booking*
  --newCardBook_background_1: #FFFFFF;
  --newCardBook_background_2: #FFFFFF;
  --newCardBook_other_border: #D9D9D9;
  --newCardBook_other_border_2: #D9D9D9;
  --newCardBook_arrived_background: #E3FAFD;
  --newCardBook_overstayed_background: #F6E9E6;
  --newCardBook_late_background: #FCEFDC;
  --newCardBook_confirmed_background: #E7F3E4;
  --newCardBook_booked_background: #F5F5F5;
  --newCardBook_main_background: #FFDFF6;
  --newCardBook_dessert_background: #FFE9D4;
  --newCardBook_other_background: #F5F5F5;
  --newCardBook_arrived_border: #00D3F2;
  --newCardBook_overstayed_border: #F7896F;
  --newCardBook_late_border: #D8BC2B;
  --newCardBook_confirmed_border: #60C689;
  --newCardBook_booked_border: #D9D9D9;
  --newCardBook_main_border: #FA97FC;
  --newCardBook_dessert_border: #FFAE52;
  --newCardBook_arrived_timer: #009CB2;
  --newCardBook_overstayed_timer: #CC5962;
  --newCardBook_late_timer: #997A00;
  --newCardBook_confirmed_timer: #00992B;
  --newCardBook_booked_timer: #313131;
  --newCardBook_main_timer: #CC3DBE;
  --newCardBook_dessert_timer: #A67135;
  --newCardBook_other_timer: #313131;

  //*Tabs*
  --tabs_inactive_content: #313131;
  --tabs_active_content: #4BA837;
  --tabs_hover_content: #4BA837;
  --tabs_disabled_content: #888888;

  //*Floorplan table*
  --floorplanTable_arrived_background: #62BFCC;
  --floorplanTable_dessert_background: #FFAE52;
  --floorplanTable_outstayed_background: #F7896F;
  --floorplanTable_main_background: #FA97FC;
  --floorplanTable_late_background: #FFAE52;
  --floorplanTable_empty_background: #FFFFFF;
  --floorplanTable_confirmed_background: #60C689;
  --floorplanTable_focus_stroke: #292929;
  --floorplanTable_text: #292929;
  --floorplanTable_manager_background: #FA97FC;

  //*Floorplan legend*
  --floorplanLegend_status_background: #F4A917;
  --floorplanLegend_status_content: #FFFFFF;
  --floorplanLegend_lock_background: #FFFFFF;
  --floorplanLegend_lock_content: #292929;
  --floorplanLegend_overbooking_background: #FE8F35;
  --floorplanLegend_overbooking_content: #FFFFFF;
  --floorplanLegend_deposite_background: #FFFFFF;
  --floorplanLegend_deposite_content: #292929;

  //*Floorplan number*
  --floorplanNumber_arrived_stroke: #62BFCC;
  --floorplanNumber_dessert_stroke: #F4A917;
  --floorplanNumber_outstayed_stroke: #F7896F;
  --floorplanNumber_main_stroke: #FA97FC;
  --floorplanNumber_late_stroke: #FFAE52;
  --floorplanNumber_empty_stroke: #FFFFFF;
  --floorplanNumber_confirmed_stroke: #60C689;
  --floorplanNumber_default_background: #F3E8DD;
  --floorplanTable_notconfirmed_background: #BDBDBD;
  --floorplanNumber_focus_background: #292929;
  --floorplanNumber_default_text: #292929;
  --floorplanNumber_focus_text: #FFFFFF;
  --floorplanNumber_confirmed_future_stroke: #1E9951;
  --floorplanNumber_notconfirmed_stroke: #BDBDBD;
  --floorplanNumber_notconfirmed_future_stroke: #ABABAB;

  // Collapse
  --collapse_list_inactive_background_2: #E7F3E4;
  --collapse_list_active_background_2: #E7F3E4;

  //*Shifts*
  --shiftsSlot_actualy_default_bg: #BBD2BC;
  --shiftsSlot_old_default_bg: #E2E2E2;

  //Grid
  --gridBookingCard_default_background: #E2E2E2;
}
