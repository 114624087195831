.title{
  text-transform: uppercase;
  color: var(--gl_text_secondary_2);
  font: var(--font-16-b);
}

.subTitle{
  font: var(--font-14-b);
  color: var(--gl_text_inverse);
  margin-top: 10px;
  margin-bottom: 10px;
}

.list{
  margin-top: 10px;
  margin-bottom: 20px;
}

.listItem{
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
}

.listItemTitle{
  color: var(--gl_text_secondary_2);
  font: var(--font-14-r);
  font-weight: 400;
}

.listItemSeparate{
  flex-grow: 1;
  position: relative;
  &:after{
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    border-top: 1px solid var(--gl_dividerBorder_primary_2);
  }
}

.listItemValue{
  color: var(--gl_text_inverse);
  font: var(--font-14-b);
}
