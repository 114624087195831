.tableNumberBody{
  display: flex;
  justify-content: center;
  background: transparent;
  overflow: unset;
}

.tableNumber{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--count-bg);
  border: 7px solid var(--count-border);
  color: var(--count-color);
  font: var(--font-14-b);
  font-size: 40px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 0 25px;
  border-radius: 30px;
}

.alarmIcon{
  color: var(--gl_text_inverse);
  margin-right: 5px;
}
