.edit {
  display: var(--book-right-arr);
  position: absolute;
  right: 6px;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;

  background: var(--btn-primary, var(--dodger-blue));
  border-radius: 5px;
  color: white;

  padding: 0;

  mask: var(--src);
  -webkit-mask: var(--src);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: contain;
  -webkit-mask-size: contain;
}

.editNew {
  background-color: var(--columnButton_active_background);
  color: var(--gl_text_constant_primary2);
}

.bookingLabel {
  span {
    font: var(--font-12-r);
    font-weight: 400;
    margin-bottom: 4px;
  }
}

.placeholderMatch:not(:focus)::placeholder {
  color: var(--gl_text_inverse);
  font: var(--font-12-r);
}
