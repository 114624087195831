@import "styles/mixins";

#guests {
    grid-template-rows: min-content auto;

    .top-block .select {
        width: 100%;
    }

    .top-block-A {
        .searches {
            .input-search:not(.select-search) {
                width: 100%;
            }

            align-items: center;
        }
    }

    .block-content {
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: var(--header-spacing);
        padding-right: var(--header-spacing);

        > :first-child:not(.guest-card-empty) {
            margin-top: var(--header-spacing);
        }
    }


    .searches {
        display: grid;
        grid-template-columns: minmax(180px, max-content) 1fr;

        @include tablet {
            display: flex;
            flex-direction: row-reverse;
            gap: 10px;

            >* {
                flex: 1 1;
            }

            .input-search {
                flex: 2 1;
            }
        }

        &.only-tags {
            grid-column-gap: normal;

            .input-search {
                display: none;
            }
        }

        >img {
            width: 40px;

            @media (min-width: 950px) {
                width: initial;
            }
        }
    }
}

.guest-card-empty {
    height: 100%;

    --msg-font: var(--font-24-b);

    @media (max-width: 1366px) {
        --msg-font: var(--font-16-b);
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-align: center;
    justify-content: center;

    .img.contact {
        @include icon-svg-color-size("contact", var(--icon-color, #bec5d1), 220px);
        margin-bottom: 70px;
    }

    p {
        font: var(--msg-font);
        color: var(--cadet-blue);
        text-align: center;
        max-width: 600px;
    }

    &.create-guest {
        p {
            padding-bottom: 40px;
            margin: 0px 100px;
            border-bottom: 1px solid #dde3ef;
        }

        button {
            margin-top: 40px;
        }
    }
}