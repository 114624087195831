.datePicker {
  border-bottom: none;
}

.container {
  border: 1px solid var(--gl_dividerBorder_primary_2);
  box-shadow: var(--shadow-lightest);
  background: var(--dtpkr_background_1);
  padding-top: 20px;
  padding-bottom: 20px;
  width: 258px;
  height: 275px;
}

.input {
  cursor: pointer;
  color: var(--gl_text_inverse);
  background: var(--dtpkr_background_1);
  border-radius: 0;
}

.inputIcon {
  width: 18px;
  height: 18px;

  path {
    fill: var(--gl_icon_constant_secondary);
  }
}

.header {
  width: 100%;
  background-color: var(--dtpkr_background_1);
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 24px 86px 62px 24px;
  grid-gap: 5px;
}

.headerButton:disabled {
  border: none;
  background: transparent;
}

.invalid {
  border-color: var(--gl_icon_constant_danger);
}