.container {
  width: 239px;
  padding: 4px 0;
  gap: 8px;
}

.header {
  color: var(--gray);
  padding: 0px 8px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 12px;

  font: var(--font-14-r);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.menu {
  max-height: 308px;
  overflow-y: auto;
}

.item {
  border-bottom: 1px solid var(--gl_dividerBorder_primary);
  padding: 0px 8px;
  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .statusName {
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.item:hover {
  background-color: var(--selectMenu_hover_background);
}
.item.active {
  background-color: var(--selectMenu_focus_background);
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
