#modal {
  .change-user {
    --pad-left: 20px;
    --max-height: 800px;
    color: #525252;
    background: var(--gl_background_3);
    box-shadow: var(--shadow-lightest);
    border-radius: 3px;
    width: 500px;
    min-height: 400px;
    max-height: var(--max-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .close {
      --icon-color: var(--gl_icon_primary_1)
    }

    .top-block-A {
      height: 72px;

      h2 {
        color: #000;
        font: var(--font-22-b);
      }

      img {
        cursor: pointer;
      }
    }

    .content {
      overflow: auto;
      padding-bottom: 20px;
      max-height: calc(var(--max-height) - 72px - 76px);

      > .titled-block, > .name-phone, > .clickable, > .login-block  {
        margin: 0px var(--pad-left);
      }

      .hr {
        margin: 20px 0px;
        border: 1px solid #DDE3EF;
        &.before-name {
          margin-bottom: 0px;
        }
      }

      @keyframes anim-error {
        from {
          transform: translate(0px, -15px);
          opacity: 0;
          margin-top: 0px;
          height: 0px;
        }
        to {
          transform: translate(0px, 0px);
          opacity: 1;
          margin-top: 8px;
          height: 16px;
          @media(max-width: 1366px) {
            height: 14px;
          }
        }
      }

      .error-block {
        font: var(--font-14-b);
        animation-duration: 0.2s;
        color: #E08D8D;
        margin-left: 20px;
        margin-right: 20px;
      }
      .error-block.active {
        margin-top: 8px;
        animation-name: anim-error;
      }

      .name-phone {
        margin-top: 20px;
      }

      > .titled-block {
        margin-top: 15px;
      }

      .titled-block {

        &.rest .wrapper-select > div, &.role .wrapper-select > div{
          top: 0;
        }

        h3 {
          font: var(--font-12-r);
          margin-bottom: 10px;
          color: var(--gl_text_inverse);
          &.required:after {
            content: "*";
            color: var(--gl_icon_constant_danger);
            font: var(--font-14-b);
          }
        }

        .hostess {
          display: flex;
          margin-top: 15px;
          img { margin-left: 10px; cursor: pointer; }
        }

        &.add {
          > :nth-child(2) {
            margin-top: 0px;
          }
          .mock-col{
            width: 34px;
          }
        }
      }

      .clickable {
        margin-top: 15px;
        font: var(--font-16-b);
      }

      .name-phone {
        display: flex;
        > :first-child {
          width: 100%;
        }
        > :last-child {
          margin-left: 15px;
          width: 35%;
        }
        .phone {
          display: none;
        }

        &.phone {
          > :first-child {
            width: 65%;
          }
          > :last-child {
            display: block;
          }
        }
      }

      &.add .role .wrapper-select > div {
        border: var(--border-2-solid-blue);
      }
    }

    .form-controls {
      box-sizing: border-box;
      height: 76px;
      padding: 18px var(--pad-left);
      background-color: var(--bottomBar_background_2);
      display: flex;
      align-items: center;
      button {
        height: var(--height-a);
        position: relative;

        &:hover {
          .tooltip {
            visibility: visible;
            opacity: 1;
          }
        }

        .tooltip {
          top: -31px;
          padding-bottom: 7px;

          &::after {
            right: 156px;
            bottom: -4px;
          }
        }
      }
    }

    @media(max-width: 1366px) {
      --pad-left: 10px;
      --max-height: 690px;
      min-height: 200px;
      width: 440px;
      .top-block-A {
        height: 50px;
        h2 { font: var(--font-16-b); }
      }
      .content {
        padding-bottom: 15px;
        .titled-block h3 {
          font: var(--font-12-b);
          &.required:after {
            font: var(--font-12-b);
          }
        }
        .hr { margin: 15px 0px; }
        .clickable {
          margin-top: 10px;
          font: var(--font-14-b);
        }
        .error-block {
          font: var(--font-12-b);
          margin-left: 10px;
          margin-right: 10px;
        }
        .name-phone {
          margin-top: 15px;
        }
      }
      .form-controls {
        box-sizing: border-box;
        height: 64px;
      }
    }
  }
}
