.radio{
  display: flex;
  align-items: center;
  color: var(--gl_text_inverse);
}

.button{
  background: var(--radioButton_default_background_1);

  &:not(:first-child):not(:last-child){
    border-radius: 0;
    border-left: none;
  }
  &:first-child{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }

  font: var(--font-12-r);

  &.active{
    background: var(--radioButton_active_background);
    color: var(--gl_text_constant_primary2);
  }
}

.disabled{
  opacity: 0.7;
}