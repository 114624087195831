.form {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  padding: 0px 12px 12px 12px;
  gap: 12px;
  align-items: start;
  justify-content: start;
  grid-auto-rows: min-content;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-height: 1050px) {
    overflow: auto;
  }

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
  }

  @media (orientation: portrait) {
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }

  .dateTimeNew {
    display: grid;
    gap: 12px;
    column-gap: 10px;
    grid-template-columns: 297px 245px;
    gap: 4px;

    @media (min-width: 744px) and (max-width: 1185px) {
      width: auto;
      grid-template-columns: 1fr;
      align-items: flex-end;
    }
  }

  .dateTimeNewEdit {
    display: grid;
    gap: 12px;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr;
    gap: 4px;

    @media (min-width: 744px) and (max-width: 1185px) {
      width: auto;
      grid-template-columns: 1fr;
      align-items: flex-end;
    }
  }

  .datTime {
    column-gap: 4px;
    display: grid;
    align-items: flex-end;
    grid-template-columns: 213px 245px 80px;

    .datePicker {
      width: 213px;

      @media (min-width: 744px) and (max-width: 1185px) {
        width: auto;
        margin-bottom: 4px;
      }
    }

    .timeSelect {
      width: 80px;

      @media (min-width: 744px) and (max-width: 1185px) {
        margin-top: 16px;
        width: auto;
      }
    }

    @media (max-width: 1185px) {
      grid-template-columns: auto;
      align-items: flex-end;
      justify-content: inherit;
    }
  }

  .isFromManagement {
    display: grid;
    grid-template-columns: auto;
    gap: 4px;

    & > :first-child {
      width: auto;
    }

    .timeSelect {
      width: auto;
    }
  }

  .dateTimeEdit {
    display: grid;
    gap: 12px;
    column-gap: 4px;
    grid-template-columns: repeat(3, auto);
    align-items: flex-end;

    .datePicker {
      width: auto;
    }

    .timeSelect {
      width: auto;

      @media (min-width: 744px) and (max-width: 1185px) {
        width: auto;
      }
    }

    @media (max-width: 1185px) {
      grid-template-columns: auto;
      align-items: flex-end;
    }
  }

  .fullRow {
    grid-column: span 2;
    max-width: 546px;

    @media (min-width: 1185px) {
      max-width: initial;
    }

    :global {
      .content,
      .hours-mins {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    }
  }

  .fullRowEdit {
    grid-column: span 2;

    :global {
      .content,
      .hours-mins {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    }
  }

  .twoCol {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    column-gap: 10px;

    @media (min-width: 744px) and (max-width: 1185px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .visitTime {
    display: grid;
    grid-column-gap: 10px;
    align-items: flex-end;
    grid-template-columns: 65px auto;
  }

  .location {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    align-items: flex-end;
  }

  .printRow {
    font: var(--font-12-r);
    font-weight: 400;
    display: flex;
    margin-top: 12px;
  }

  .formElement {
    width: 100%;
    border-right: none;
    padding-right: 0px;
    padding-top: 0px;
    height: initial;
    border-top: 1px solid var(--gl_dividerBorder_primary);
    padding-top: 12px;

    @media (min-width: 744px) and (max-width: 1185px) {
      display: grid;
      align-items: flex-start;
      border-top: none;
      height: inherit;
      grid-template-columns: auto;

      &:nth-child(3) {
        .eachElementGrid,
        .eachElementGridEdit {
          padding-right: 0;
        }
      }

      &:nth-child(3) {
        border-right: none;
        align-content: baseline;
        gap: 12px;
      }
    }

    @media (orientation: portrait) {
      border-right: none;
      border-top: 1px solid var(--gl_dividerBorder_primary);

      &:first-child {
        border-top: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .formElement {
      border-right: none;
    }

    .eachElementGrid {
      max-width: 546px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 12px;
      margin-top: 12px;

      @media (min-width: 744px) and (max-width: 1185px) {
        margin-top: 0px;
      }
    }

    .eachElementGridEdit {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 12px;
      margin-top: 12px;

      @media (min-width: 744px) and (max-width: 1185px) {
        margin-top: 0px;
        padding-right: 12px;
        @media (orientation: portrait) {
          padding-right: 0px;
        }
      }
    }
  }

  .formElementWithoutPadding {
    @media (min-width: 1185px) {
      padding-top: 0;
    }
  }

  .columnOne {
    @media (min-width: 744px) and (max-width: 1185px) {
      grid-column-start: 1;
    }
    @media (orientation: portrait) {
      grid-row-start: 1;
      grid-column-start: 1;
    }
  }

  .columnTwo {
    @media (min-width: 744px) and (max-width: 1185px) {
      grid-row: 1 / 3;
      grid-column-start: 2;
    }
    @media (orientation: portrait) {
      grid-row-start: 2;
      grid-column-start: 1;
    }
  }

  .columnThree {
    @media (min-width: 744px) and (max-width: 1185px) {
      grid-row: 1 / 3;
      grid-column-start: 3;
    }
    @media (orientation: portrait) {
      grid-row-start: 3;
      grid-column-start: 1;
    }
  }

  .twoElems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    align-items: flex-end;

    @media (min-width: 744px) and (max-width: 1185px) {
      gap: 0;
      row-gap: 16px;
      grid-template-columns: 1fr !important;
    }
  }

  .OneOrThreeElems {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    align-items: flex-end;

    @media (max-width: 1185px) {
      gap: 16px;
      grid-template-columns: 1fr;
    }
  }

  .threeElems {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
  }

  .visitTimeNewDesign {
    display: grid;
    gap: 4px;
    grid-template-columns: 65px auto;
  }

  .dontShowInSmallSize {
    display: none;
  }
}

.formWithHeight {
  max-height: 600px;
}

.twoElemsTabels {
  display: grid;
  grid-template-columns: 150px 106px;
  gap: 8px;
  align-items: flex-end;

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: repeat(2, auto);
  }
}

.twoElemsTabelsEdit {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
  align-items: flex-end;

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: repeat(2, auto);
  }
}

.placeSelect {
  display: grid;
  grid-template-columns: auto;
  gap: 8px;

  button {
    display: none;
  }

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: 10fr 1fr;
    gap: 4px;

    button {
      display: grid;
    }
  }
}

.openScheme {
  svg {
    color: var(--columnButton_active_background);
  }

  &:disabled {
    svg {
      opacity: 0.7;
    }
  }
}

.gap16 {
  gap: 16px !important;
}

.soruceAndDeposit {
  display: grid;
  grid-template-columns: repeat(2, 265px) !important;
  gap: 16px !important;
}

.soruceAndDepositEdit {
  display: grid;
  grid-template-columns: repeat(2, auto) !important;
  gap: 16px !important;
}

.bookingLabel {
  span {
    font: var(--font-12-r);
    font-weight: 400;
  }
}

.comment {
  textarea {
    height: 50px;
    resize: none;
    overflow: hidden;
  }
}

.notes {
  textarea {
    height: 30px;
    resize: none;
    overflow: hidden;
  }
}

.none {
  display: none !important;
}

.placeselect {
  display: grid;
  gap: 4px;
  grid-template-columns: 10fr 1fr;
}

.phoneRadioButton {
  width: 50%;
  font-weight: 400;
}
.actions {
  display: flex;
  background-color: var(--columnButton_secondary_background);
  color: var(--gl_icon_constant_primary2);
  --icon-color: currentColor;
  width: 31px;
  height: 31px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border-bottom: 1px solid var(--newCardBook_booked_border);

  &:active,
  &:focus,
  &:hover {
    background-color: var(--columnButton_active_background);
  }
}

/* .bottomBar {
  @media (orientation: portrait) {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;
    justify-content: start;
    gap: 5px;
    min-height: 79px;

    :first-child {  
      grid-column: auto;
      //align-self: start;
    }

    :nth-child(2) {
      grid-column: span 3;
    }
  }
} */
.bottomBarCompact {
  display: inline-flex;
  justify-content: space-between;
}

.bottomBarTabletCompact {
  @media (orientation: portrait) {
    display: inline-flex;
    justify-content: space-between;
  }
}
